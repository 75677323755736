@mixin mobile {
    @media(max-width: 500px) {@content; }
}

@mixin tablet {
    @media(max-width: 800px) {@content; }
}

@mixin xl-tablet {
    @media(max-width: 1400px) {@content; }
}