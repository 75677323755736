
.nav-bar {
    position: fixed;
    height: 100vh;
    width: 10%;
    left: 0;
    top: 0;
    z-index: 1000001;
    background-color: darken($font-color, 15%);
    font-family: 'Montserrat', 'Merriweather', 'Times New Roman', Times, serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    transition: background-color .25s ease-in;
    

    @include xl-tablet {
        flex-direction: row;
        height: 7vh;
        width: 100vw;
        left: 0;
        right: 0;
        margin-bottom: 40px;
        background-color: darken($font-color, 15%);
        
        transition: background .4s ease-in-out, height .4s ease-in-out;

    }


    img{
        height: 13%;

        @include xl-tablet{
            height: 70%;
        }

        @include mobile{
            height: 60%;
        }

    }


    .nav-links {
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-size: 1.3rem;
        list-style: none;
        
        a{
            color: $background-color;
            transition: all .25s ease-in;

        }

        @include xl-tablet {
            height: 100%;
            width: 70%;
            flex-direction: row;  
            font-size: .6rem;
            // border: 2px solid red; 
        }

        :hover {
            text-shadow: 1px 0 $orange;
        }

        @include mobile {
            width: 75%;
            height: 100%;
            flex-direction: row;
            margin: auto 0;
        }

        .nav-item {
            cursor: pointer;
            text-align: center;
            width: 100%;

            @include mobile {
                text-align: center;
            }

        }
    }
    .social{
        height: 7%;
        width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        // border:2px solid red;
        
        @include xl-tablet{
            display: none;
        }

        a{
            width: 50%;
            margin: auto 0;
            padding: .5rem;

            img{
                width: 100%;
                height: auto;
            }


        }

    }



}

.scrolled{
    height: 10vh;
    background-color: darken($font-color, 15%);
    transition: background .4s ease-in-out, height .4s ease-in-out;
    a{
        font-size: .8rem;

    }
}


body.dark-mode .nav-bar{
    background-color: #131313;
    transition: all .25s ease-in;

    .nav-links a{
        color: $font-color;
        transition: all .25s ease-in;
    }
}
