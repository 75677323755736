.landing-container {
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
    position: relative;
    font-family: 'Montserrat', 'Merriweather', 'Times New Roman', Times, serif;
    background: $font-color;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    transition: background .25s ease-in;
    

    .web {
        color: $background-color;

        position: absolute;
        bottom: 40%;
        left: 13.5%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: 'Montserrat', 'Merriweather', 'Times New Roman', Times, serif;
        transition: color .25s ease-in;

        @include xl-tablet {
            bottom: 0;
            left: 0;
            bottom: 68%;
            margin-left: 5%;
        }

        @include mobile {
            bottom: 0;
            left: 0;
            bottom: 70%;
            left: 0%;
            margin-left: 5%;
        }


        p {
            font-size: 14rem;
            font-weight: 700;
            letter-spacing: .5rem;

            @include xl-tablet {
                font-size: 9rem;
                letter-spacing: 0;
            }

            @include mobile {
                font-size: 5.2rem;
                letter-spacing: 0;
            }


        }

        .opening-tags {
            color: $green;
            font-size: 1.2rem;
            font-family: 'Nothing You Could Do', 'Liu Jian Mao Cao', 'Merriweather', 'Times New Roman', Times, serif;
            letter-spacing: .1rem;

            @include xl-tablet {
                letter-spacing: 0;
            }

            @include mobile {
                font-size: 1rem;
                letter-spacing: 0;
            }
        }

        .web-text {
            position: relative;

            .hello {
                font-size: 5.5rem;
                font-family: 'Montserrat', 'Merriweather', 'Times New Roman', Times, serif;
                position: absolute;
                left: 84%;
                letter-spacing: 1rem;
                transform: rotate(-90deg);
                color: $green;
                font-weight: 900;
                transition: color .25s ease-in;

                @include xl-tablet {
                    left: 0;
                    left: 90%;
                    top: 25%;
                    font-size: 2.4rem;
                    letter-spacing: .3rem;
                }


                @include mobile {
                    right: -33%;
                    bottom: 60%;
                    font-size: 1.5rem;
                    letter-spacing: .3rem;
                    
                }


                .period {
                    color: $orange;
                }
            }
        }
    }

    .dev {
        color: $background-color;
        position: absolute;
        bottom: 8%;
        left: 24.5%;
        display: flex;
        flex-direction: column;
        font-weight: 700;
        font-family: 'Montserrat', 'Merriweather', 'Times New Roman', Times, serif;
        transition: color .25s ease-in;
        @include xl-tablet {
            bottom: 0;
            left: 0;
            right: 0;
            bottom: 48%;
            text-align: right;
            margin-right: 5%;

        }

        @include mobile {
            bottom: 54%;
            left: 0;
            right: 0;
            text-align: right;
            margin-right: 5%;

        }


        .dev-text {
            font-size: 14rem;
            letter-spacing: .5rem;

            @include xl-tablet {
                font-size: 9rem;
            }

            @include mobile {
                font-size: 5.2rem;
                justify-content: flex-start;
                letter-spacing: 0;
            }
        }

        .closing-tags {
            font-size: 1.2rem;
            color: $green;
            text-align: right;
            font-family: 'Nothing You Could Do', 'Liu Jian Mao Cao', 'Merriweather', 'Times New Roman', Times, serif;
            letter-spacing: .1rem;

            @include xl-tablet {
                letter-spacing: 0;
            }

            @include mobile {
                text-align: right;
                font-size: 1rem;
                letter-spacing: 0;
            }
        }
    }

    .name-container {
        color: $background-color;
        max-width: 40%;
        height: 100%;
        font-family: 'Montserrat', 'Merriweather', 'Times New Roman', Times, serif;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 62%;

        @include xl-tablet {
            position: absolute;
            padding: 0;
            width: 90%;
            max-width: 90%;
            margin: 0 5% 0 5%;
            height: 48%;
            bottom: 0;

        }

        @include mobile {
            position: absolute;
            top: 43%;
            margin-left: 5%;
            height: 50%;
            padding: 0;
            max-width: 100%;
            font-size: 1rem;
            letter-spacing: 0;
        }

        .name {
            font-size: 7.8rem;
            font-weight: 900;
            line-height: 1.5;

            @include xl-tablet {
                font-size: 5.8rem;
                
            }

            @include mobile {
                font-size: 4rem;
                width: 82%;
            }
        }

        span {
            color: $green
        }

        .role {
            font-size: 1.2rem;
            text-align: right;
            margin: 0 12% 5% 0;
            line-height: .5;

            @include xl-tablet {
                margin: 0 0 5% 0;
                text-align: right;
            }

            @include mobile {
                width: 100vw;
                text-align: center;
                margin: 0;
                transform: translateX(-5%);
            }
        }
    }
}

.hide {
    display: none;
}

body.dark-mode .landing-container{
    background: $background-color;
    transition: background .25s ease-in;
    .web-text, .dev-text{
        color: $font-color;
        transition: color .25s ease-in;

    }


    .name-container{
        color: $font-color;
        transition: color .25s ease-in;

    }
}