.projects-section {
    overflow: hidden;
    background: $font-color;
    min-height: 100vh;
    font-family: 'Montserrat', 'Merriweather', 'Times New Roman', Times, serif;
    max-width: 100vw;
    position: relative;
    transition: background .25s ease-in;
    
    @include xl-tablet {
        min-height: 0;
        padding-bottom: 2rem;
    }
    
    @include mobile {
        // overflow: visible;
        height: 100%;
        padding: 2rem 0 0 0;
    }

    .projects-container {
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 20%;
        margin-top: 5%;

        @include xl-tablet {
            margin-left: 0;
            width: 90%;
            justify-content: space-around;
            padding: 0 5%;
        }

        @include mobile {
            margin: 0;
            padding: 0;
            width: 100%;
            justify-content: space-around;
            margin-left: 0;
        }

        h1 {
            color: $background-color;
            font-size: 2.6rem;
            text-align: left;
            transition: color .25s ease-in;
            padding-bottom: 1.8rem;

            @include mobile {
                font-size: 1.8rem;
                margin-left: 5%;
                text-decoration-line: none;
                margin-bottom: 4%;
            }
        }

        .project-container {
            width: 92%;
            padding: 40px 0 20px 0;
            
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            color: $background-color;
            position: relative;

            transition: color .25s ease-in;

            @include xl-tablet {
                padding: 3rem 0;
                margin: 0;
                width: 100%;
                flex-direction: row;
                flex-wrap: wrap;
            }

            @include mobile {
                height: 30%;
                width: 90%;
                padding: 1rem 0 2rem;
                margin: 0 0 0 5%;
                overflow: hidden;
                justify-content: space-between;

            }

            .project-header {
                font-size: 1.8rem;
                width: 90%;
                z-index: 1;

                @include xl-tablet {
                    margin-bottom: 0;
                    font-weight: 800;
                    width: 100%;
                }
                // TODO
                @include mobile {
                    margin-bottom: 0;
                    font-weight: 0;
                    font-size: 1.2rem;
                }
            }


            .img-description {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 100%;
                padding: 1rem 0;
                flex-wrap: wrap;

                @include xl-tablet {
                    justify-content: space-between;

                }

                @include mobile {
                    flex-direction: column;
                    flex-wrap: nowrap;
                    width: 100%;
                    margin-top: 3%;
                    height: 100%;

                }

                .project-img {
                    height: 11rem;
                    width: auto;
                    position: absolute;
                    top: 5rem;
                    z-index: 0;
                    filter: drop-shadow(0px 5px 3px black);

                    transition: 
                    height .15s ease-in-out,
                    width .15s ease-in-out;
                    
                    cursor: pointer;

                    @include xl-tablet {
                        margin: 0;
                        padding: 5% 0 0;
                        width: auto;
                        height: 8rem;
                        margin: auto 0;
                        
                    }

                    @include tablet{
                        right: -20rem;
                        transform: translate(30%, 10%);
                    }

                    @include mobile {
                        margin: 0;
                        position: initial;
                        height: 5rem;
                        width: auto;
                        
                    }
                }

                .project-img.mobile{
                    right: 10rem;
                    z-index: 10;

                    @include xl-tablet {
                        right: 8rem;
                        bottom: -12rem;
                    }

                    @include mobile {
                        margin: 0;
                        padding: 0;
                        transform: translate(-200%, 50%), ;
                        
                    }

                }

                .project-img.desktop{
                    right: -6rem;
                    z-index: 8;

                    @include xl-tablet {
                        right: 9rem;
                        bottom: -1rem;
                    }

                    
                    @include mobile {
                        margin: 0;
                        transform: translate(30%, -10%);
                        padding: 10% 0;
                        
                        
                    }

                }

                .project-img:hover{

                    height:14rem;
                    z-index:11;

                }

                .project-img.desktop.no-mobile{
                    right: -5rem;
                    z-index: 8;

                    @include xl-tablet {
                        right: 7rem;
                        bottom: -5rem;
                    }

                    @include mobile {
                        transform: translate(-3%, 3%);
                    }

                }

                
                .project-description {
                    font-size: 1.3rem;
                    line-height: 1.75;
                    width: 60%;
                    color: $background-color;
                    transition: color .25s ease-in;

                    @include xl-tablet {
                        width: 100%;
                    }

                    @include mobile {
                        line-height: 1.5;
                        height: auto;
                        margin: 0 auto;
                        font-size: 1rem;

                        
                    }
                }

                .meta{

                    font-size: .9rem;
                    font-weight: lighter;
                    margin-top: 1.2rem;

                    a{
                        text-decoration:none;
                        color: $orange;
                        font-weight: bold;
                    }
                }



            }

            .mobile-mock {
                padding: 0;
                margin: 0;
                height: 100%;
                margin-top: -8%;

                .project-description {
                    margin-top: 5%;
                    @include mobile{
                        
                    }
                }

                @include xl-tablet {
                    margin-top: 0;
                }

                
            }

            .project-links {
                width: 50%;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                align-items: flex-end;
                margin-top: 2%;
                @include xl-tablet {
                    height: 30%;
                    width: 100%;
                    align-items: flex-start;
                    justify-content: space-between;
                    flex-direction: row;
                }

                @include mobile {
                    width: 100%;
                    align-items: flex-start;
                    

                }

                .project-link {
                    margin: 0 0 20px 20px;

                    @include xl-tablet {
                        margin: 5% 5% 5% 0;
                    }

                    @include mobile {
                        margin: 2% 2%;
                    }

                    a {
                        font-size: 1.2rem;
                        text-decoration: none;
                        width: 100%;
                        color: $background-color;
                        display: flex;
                        align-items: center;
                        transition: color .25s ease-in;

                        @include mobile {
                            width: 100%;
                            font-size: .8rem;

                        }
                    }

                    .icon {
                        width: 48px;
                        cursor: pointer;
                        padding-right: 10px;
                    }
                }
            }
            
        }
        
    }
}

.tech_project-links {
    width: 108.5%;
    display: flex;
    justify-content: space-between;
    
    @include xl-tablet {
        width: 50%;
        height: auto;
        flex-direction: column;
    }

    @include mobile {
        width: 100%;
    }

.tech-stack {

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 70%;

    @include xl-tablet {
        width: 100%;
        padding: 1.5rem 0;
        margin-top: 1.5rem;
        
    }

    @include mobile{
        padding: 0;
        margin: 0;
        margin-top: 1rem;;
    }

    h4 {
        // text-align: center;
        font-size:1.2rem;
        font-weight: 800;
        @include xl-tablet {
            text-align: left;
            font-size: 1.2rem;
            padding-bottom: 1rem;
        }

    }

    ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        li {
            font-size: 1rem;
            margin: 0 3rem 0 0;

            @include xl-tablet {
                margin: 1rem 1rem 1rem 0;
            }
        }
    }
}
}

body.dark-mode .projects-section{
    background: $background-color;
    transition: background-color .25s ease-in;

    h1, .project-container{
        color: $font-color;
        transition: color .25s ease-in;
    }

    .project-container{

        .img-description{
            .project-description{
                color: $font-color;
                transition: color .25s ease-in;

            }
        }

        .project-links{
            .project-link{
                a{
                    color: $font-color;
                    transition: color .25s ease-in;
        
                }
            }
            
        }
        
    }
}

.parent_project{
    transform: translateX(75vw);
    opacity: 0.1;
    transition: transform .75s cubic-bezier(0.175, 0.885, 0.32, 1.275)
, opacity .75s cubic-bezier(0.175, 0.885, 0.32, 1.275)
;   
    @include xl-tablet{

    }

}

.parent_project.fade_in{
    transform: translateX(0);
    opacity: 1;
    transition: transform .75s cubic-bezier(0.175, 0.885, 0.32, 1.275)
, opacity .75s cubic-bezier(0.175, 0.885, 0.32, 1.275)
;
}