@import url(https://fonts.googleapis.com/css?family=Merriweather|Liu+Jian+Mao+Cao|Nothing+You+Could+Do|Montserrat&display=swap);
html {
  font-size: 62.5%; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.nav-bar {
  position: fixed;
  height: 100vh;
  width: 10%;
  left: 0;
  top: 0;
  z-index: 1000001;
  background-color: #d1d1d1;
  font-family: 'Montserrat', 'Merriweather', 'Times New Roman', Times, serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: background-color .25s ease-in; }
  @media (max-width: 1400px) {
    .nav-bar {
      flex-direction: row;
      height: 7vh;
      width: 100vw;
      left: 0;
      right: 0;
      margin-bottom: 40px;
      background-color: #d1d1d1;
      transition: background .4s ease-in-out, height .4s ease-in-out; } }
  .nav-bar img {
    height: 13%; }
    @media (max-width: 1400px) {
      .nav-bar img {
        height: 70%; } }
    @media (max-width: 500px) {
      .nav-bar img {
        height: 60%; } }
  .nav-bar .nav-links {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 1.3rem;
    list-style: none; }
    .nav-bar .nav-links a {
      color: #1a1a1a;
      transition: all .25s ease-in; }
    @media (max-width: 1400px) {
      .nav-bar .nav-links {
        height: 100%;
        width: 70%;
        flex-direction: row;
        font-size: .6rem; } }
    .nav-bar .nav-links :hover {
      text-shadow: 1px 0 #ff5252; }
    @media (max-width: 500px) {
      .nav-bar .nav-links {
        width: 75%;
        height: 100%;
        flex-direction: row;
        margin: auto 0; } }
    .nav-bar .nav-links .nav-item {
      cursor: pointer;
      text-align: center;
      width: 100%; }
      @media (max-width: 500px) {
        .nav-bar .nav-links .nav-item {
          text-align: center; } }
  .nav-bar .social {
    height: 7%;
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 1400px) {
      .nav-bar .social {
        display: none; } }
    .nav-bar .social a {
      width: 50%;
      margin: auto 0;
      padding: .5rem; }
      .nav-bar .social a img {
        width: 100%;
        height: auto; }

.scrolled {
  height: 10vh;
  background-color: #d1d1d1;
  transition: background .4s ease-in-out, height .4s ease-in-out; }
  .scrolled a {
    font-size: .8rem; }

body.dark-mode .nav-bar {
  background-color: #131313;
  transition: all .25s ease-in; }
  body.dark-mode .nav-bar .nav-links a {
    color: #F7F7F7;
    transition: all .25s ease-in; }

.landing-container {
  height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  position: relative;
  font-family: 'Montserrat', 'Merriweather', 'Times New Roman', Times, serif;
  background: #F7F7F7;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  transition: background .25s ease-in; }
  .landing-container .web {
    color: #1a1a1a;
    position: absolute;
    bottom: 40%;
    left: 13.5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Montserrat', 'Merriweather', 'Times New Roman', Times, serif;
    transition: color .25s ease-in; }
    @media (max-width: 1400px) {
      .landing-container .web {
        bottom: 0;
        left: 0;
        bottom: 68%;
        margin-left: 5%; } }
    @media (max-width: 500px) {
      .landing-container .web {
        bottom: 0;
        left: 0;
        bottom: 70%;
        left: 0%;
        margin-left: 5%; } }
    .landing-container .web p {
      font-size: 14rem;
      font-weight: 700;
      letter-spacing: .5rem; }
      @media (max-width: 1400px) {
        .landing-container .web p {
          font-size: 9rem;
          letter-spacing: 0; } }
      @media (max-width: 500px) {
        .landing-container .web p {
          font-size: 5.2rem;
          letter-spacing: 0; } }
    .landing-container .web .opening-tags {
      color: #0097a7;
      font-size: 1.2rem;
      font-family: 'Nothing You Could Do', 'Liu Jian Mao Cao', 'Merriweather', 'Times New Roman', Times, serif;
      letter-spacing: .1rem; }
      @media (max-width: 1400px) {
        .landing-container .web .opening-tags {
          letter-spacing: 0; } }
      @media (max-width: 500px) {
        .landing-container .web .opening-tags {
          font-size: 1rem;
          letter-spacing: 0; } }
    .landing-container .web .web-text {
      position: relative; }
      .landing-container .web .web-text .hello {
        font-size: 5.5rem;
        font-family: 'Montserrat', 'Merriweather', 'Times New Roman', Times, serif;
        position: absolute;
        left: 84%;
        letter-spacing: 1rem;
        transform: rotate(-90deg);
        color: #0097a7;
        font-weight: 900;
        transition: color .25s ease-in; }
        @media (max-width: 1400px) {
          .landing-container .web .web-text .hello {
            left: 0;
            left: 90%;
            top: 25%;
            font-size: 2.4rem;
            letter-spacing: .3rem; } }
        @media (max-width: 500px) {
          .landing-container .web .web-text .hello {
            right: -33%;
            bottom: 60%;
            font-size: 1.5rem;
            letter-spacing: .3rem; } }
        .landing-container .web .web-text .hello .period {
          color: #ff5252; }
  .landing-container .dev {
    color: #1a1a1a;
    position: absolute;
    bottom: 8%;
    left: 24.5%;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-family: 'Montserrat', 'Merriweather', 'Times New Roman', Times, serif;
    transition: color .25s ease-in; }
    @media (max-width: 1400px) {
      .landing-container .dev {
        bottom: 0;
        left: 0;
        right: 0;
        bottom: 48%;
        text-align: right;
        margin-right: 5%; } }
    @media (max-width: 500px) {
      .landing-container .dev {
        bottom: 54%;
        left: 0;
        right: 0;
        text-align: right;
        margin-right: 5%; } }
    .landing-container .dev .dev-text {
      font-size: 14rem;
      letter-spacing: .5rem; }
      @media (max-width: 1400px) {
        .landing-container .dev .dev-text {
          font-size: 9rem; } }
      @media (max-width: 500px) {
        .landing-container .dev .dev-text {
          font-size: 5.2rem;
          justify-content: flex-start;
          letter-spacing: 0; } }
    .landing-container .dev .closing-tags {
      font-size: 1.2rem;
      color: #0097a7;
      text-align: right;
      font-family: 'Nothing You Could Do', 'Liu Jian Mao Cao', 'Merriweather', 'Times New Roman', Times, serif;
      letter-spacing: .1rem; }
      @media (max-width: 1400px) {
        .landing-container .dev .closing-tags {
          letter-spacing: 0; } }
      @media (max-width: 500px) {
        .landing-container .dev .closing-tags {
          text-align: right;
          font-size: 1rem;
          letter-spacing: 0; } }
  .landing-container .name-container {
    color: #1a1a1a;
    max-width: 40%;
    height: 100%;
    font-family: 'Montserrat', 'Merriweather', 'Times New Roman', Times, serif;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 62%; }
    @media (max-width: 1400px) {
      .landing-container .name-container {
        position: absolute;
        padding: 0;
        width: 90%;
        max-width: 90%;
        margin: 0 5% 0 5%;
        height: 48%;
        bottom: 0; } }
    @media (max-width: 500px) {
      .landing-container .name-container {
        position: absolute;
        top: 43%;
        margin-left: 5%;
        height: 50%;
        padding: 0;
        max-width: 100%;
        font-size: 1rem;
        letter-spacing: 0; } }
    .landing-container .name-container .name {
      font-size: 7.8rem;
      font-weight: 900;
      line-height: 1.5; }
      @media (max-width: 1400px) {
        .landing-container .name-container .name {
          font-size: 5.8rem; } }
      @media (max-width: 500px) {
        .landing-container .name-container .name {
          font-size: 4rem;
          width: 82%; } }
    .landing-container .name-container span {
      color: #0097a7; }
    .landing-container .name-container .role {
      font-size: 1.2rem;
      text-align: right;
      margin: 0 12% 5% 0;
      line-height: .5; }
      @media (max-width: 1400px) {
        .landing-container .name-container .role {
          margin: 0 0 5% 0;
          text-align: right; } }
      @media (max-width: 500px) {
        .landing-container .name-container .role {
          width: 100vw;
          text-align: center;
          margin: 0;
          transform: translateX(-5%); } }

.hide {
  display: none; }

body.dark-mode .landing-container {
  background: #1a1a1a;
  transition: background .25s ease-in; }
  body.dark-mode .landing-container .web-text, body.dark-mode .landing-container .dev-text {
    color: #F7F7F7;
    transition: color .25s ease-in; }
  body.dark-mode .landing-container .name-container {
    color: #F7F7F7;
    transition: color .25s ease-in; }

.projects-section {
  overflow: hidden;
  background: #F7F7F7;
  min-height: 100vh;
  font-family: 'Montserrat', 'Merriweather', 'Times New Roman', Times, serif;
  max-width: 100vw;
  position: relative;
  transition: background .25s ease-in; }
  @media (max-width: 1400px) {
    .projects-section {
      min-height: 0;
      padding-bottom: 2rem; } }
  @media (max-width: 500px) {
    .projects-section {
      height: 100%;
      padding: 2rem 0 0 0; } }
  .projects-section .projects-container {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 20%;
    margin-top: 5%; }
    @media (max-width: 1400px) {
      .projects-section .projects-container {
        margin-left: 0;
        width: 90%;
        justify-content: space-around;
        padding: 0 5%; } }
    @media (max-width: 500px) {
      .projects-section .projects-container {
        margin: 0;
        padding: 0;
        width: 100%;
        justify-content: space-around;
        margin-left: 0; } }
    .projects-section .projects-container h1 {
      color: #1a1a1a;
      font-size: 2.6rem;
      text-align: left;
      transition: color .25s ease-in;
      padding-bottom: 1.8rem; }
      @media (max-width: 500px) {
        .projects-section .projects-container h1 {
          font-size: 1.8rem;
          margin-left: 5%;
          -webkit-text-decoration-line: none;
                  text-decoration-line: none;
          margin-bottom: 4%; } }
    .projects-section .projects-container .project-container {
      width: 92%;
      padding: 40px 0 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      color: #1a1a1a;
      position: relative;
      transition: color .25s ease-in; }
      @media (max-width: 1400px) {
        .projects-section .projects-container .project-container {
          padding: 3rem 0;
          margin: 0;
          width: 100%;
          flex-direction: row;
          flex-wrap: wrap; } }
      @media (max-width: 500px) {
        .projects-section .projects-container .project-container {
          height: 30%;
          width: 90%;
          padding: 1rem 0 2rem;
          margin: 0 0 0 5%;
          overflow: hidden;
          justify-content: space-between; } }
      .projects-section .projects-container .project-container .project-header {
        font-size: 1.8rem;
        width: 90%;
        z-index: 1; }
        @media (max-width: 1400px) {
          .projects-section .projects-container .project-container .project-header {
            margin-bottom: 0;
            font-weight: 800;
            width: 100%; } }
        @media (max-width: 500px) {
          .projects-section .projects-container .project-container .project-header {
            margin-bottom: 0;
            font-weight: 0;
            font-size: 1.2rem; } }
      .projects-section .projects-container .project-container .img-description {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 1rem 0;
        flex-wrap: wrap; }
        @media (max-width: 1400px) {
          .projects-section .projects-container .project-container .img-description {
            justify-content: space-between; } }
        @media (max-width: 500px) {
          .projects-section .projects-container .project-container .img-description {
            flex-direction: column;
            flex-wrap: nowrap;
            width: 100%;
            margin-top: 3%;
            height: 100%; } }
        .projects-section .projects-container .project-container .img-description .project-img {
          height: 11rem;
          width: auto;
          position: absolute;
          top: 5rem;
          z-index: 0;
          -webkit-filter: drop-shadow(0px 5px 3px black);
                  filter: drop-shadow(0px 5px 3px black);
          transition: height .15s ease-in-out, width .15s ease-in-out;
          cursor: pointer; }
          @media (max-width: 1400px) {
            .projects-section .projects-container .project-container .img-description .project-img {
              margin: 0;
              padding: 5% 0 0;
              width: auto;
              height: 8rem;
              margin: auto 0; } }
          @media (max-width: 800px) {
            .projects-section .projects-container .project-container .img-description .project-img {
              right: -20rem;
              transform: translate(30%, 10%); } }
          @media (max-width: 500px) {
            .projects-section .projects-container .project-container .img-description .project-img {
              margin: 0;
              position: static;
              position: initial;
              height: 5rem;
              width: auto; } }
        .projects-section .projects-container .project-container .img-description .project-img.mobile {
          right: 10rem;
          z-index: 10; }
          @media (max-width: 1400px) {
            .projects-section .projects-container .project-container .img-description .project-img.mobile {
              right: 8rem;
              bottom: -12rem; } }
          @media (max-width: 500px) {
            .projects-section .projects-container .project-container .img-description .project-img.mobile {
              margin: 0;
              padding: 0;
              transform: translate(-200%, 50%); } }
        .projects-section .projects-container .project-container .img-description .project-img.desktop {
          right: -6rem;
          z-index: 8; }
          @media (max-width: 1400px) {
            .projects-section .projects-container .project-container .img-description .project-img.desktop {
              right: 9rem;
              bottom: -1rem; } }
          @media (max-width: 500px) {
            .projects-section .projects-container .project-container .img-description .project-img.desktop {
              margin: 0;
              transform: translate(30%, -10%);
              padding: 10% 0; } }
        .projects-section .projects-container .project-container .img-description .project-img:hover {
          height: 14rem;
          z-index: 11; }
        .projects-section .projects-container .project-container .img-description .project-img.desktop.no-mobile {
          right: -5rem;
          z-index: 8; }
          @media (max-width: 1400px) {
            .projects-section .projects-container .project-container .img-description .project-img.desktop.no-mobile {
              right: 7rem;
              bottom: -5rem; } }
          @media (max-width: 500px) {
            .projects-section .projects-container .project-container .img-description .project-img.desktop.no-mobile {
              transform: translate(-3%, 3%); } }
        .projects-section .projects-container .project-container .img-description .project-description {
          font-size: 1.3rem;
          line-height: 1.75;
          width: 60%;
          color: #1a1a1a;
          transition: color .25s ease-in; }
          @media (max-width: 1400px) {
            .projects-section .projects-container .project-container .img-description .project-description {
              width: 100%; } }
          @media (max-width: 500px) {
            .projects-section .projects-container .project-container .img-description .project-description {
              line-height: 1.5;
              height: auto;
              margin: 0 auto;
              font-size: 1rem; } }
        .projects-section .projects-container .project-container .img-description .meta {
          font-size: .9rem;
          font-weight: lighter;
          margin-top: 1.2rem; }
          .projects-section .projects-container .project-container .img-description .meta a {
            text-decoration: none;
            color: #ff5252;
            font-weight: bold; }
      .projects-section .projects-container .project-container .mobile-mock {
        padding: 0;
        margin: 0;
        height: 100%;
        margin-top: -8%; }
        .projects-section .projects-container .project-container .mobile-mock .project-description {
          margin-top: 5%; }
        @media (max-width: 1400px) {
          .projects-section .projects-container .project-container .mobile-mock {
            margin-top: 0; } }
      .projects-section .projects-container .project-container .project-links {
        width: 50%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 2%; }
        @media (max-width: 1400px) {
          .projects-section .projects-container .project-container .project-links {
            height: 30%;
            width: 100%;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: row; } }
        @media (max-width: 500px) {
          .projects-section .projects-container .project-container .project-links {
            width: 100%;
            align-items: flex-start; } }
        .projects-section .projects-container .project-container .project-links .project-link {
          margin: 0 0 20px 20px; }
          @media (max-width: 1400px) {
            .projects-section .projects-container .project-container .project-links .project-link {
              margin: 5% 5% 5% 0; } }
          @media (max-width: 500px) {
            .projects-section .projects-container .project-container .project-links .project-link {
              margin: 2% 2%; } }
          .projects-section .projects-container .project-container .project-links .project-link a {
            font-size: 1.2rem;
            text-decoration: none;
            width: 100%;
            color: #1a1a1a;
            display: flex;
            align-items: center;
            transition: color .25s ease-in; }
            @media (max-width: 500px) {
              .projects-section .projects-container .project-container .project-links .project-link a {
                width: 100%;
                font-size: .8rem; } }
          .projects-section .projects-container .project-container .project-links .project-link .icon {
            width: 48px;
            cursor: pointer;
            padding-right: 10px; }

.tech_project-links {
  width: 108.5%;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 1400px) {
    .tech_project-links {
      width: 50%;
      height: auto;
      flex-direction: column; } }
  @media (max-width: 500px) {
    .tech_project-links {
      width: 100%; } }
  .tech_project-links .tech-stack {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 70%; }
    @media (max-width: 1400px) {
      .tech_project-links .tech-stack {
        width: 100%;
        padding: 1.5rem 0;
        margin-top: 1.5rem; } }
    @media (max-width: 500px) {
      .tech_project-links .tech-stack {
        padding: 0;
        margin: 0;
        margin-top: 1rem; } }
    .tech_project-links .tech-stack h4 {
      font-size: 1.2rem;
      font-weight: 800; }
      @media (max-width: 1400px) {
        .tech_project-links .tech-stack h4 {
          text-align: left;
          font-size: 1.2rem;
          padding-bottom: 1rem; } }
    .tech_project-links .tech-stack ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap; }
      .tech_project-links .tech-stack ul li {
        font-size: 1rem;
        margin: 0 3rem 0 0; }
        @media (max-width: 1400px) {
          .tech_project-links .tech-stack ul li {
            margin: 1rem 1rem 1rem 0; } }

body.dark-mode .projects-section {
  background: #1a1a1a;
  transition: background-color .25s ease-in; }
  body.dark-mode .projects-section h1, body.dark-mode .projects-section .project-container {
    color: #F7F7F7;
    transition: color .25s ease-in; }
  body.dark-mode .projects-section .project-container .img-description .project-description {
    color: #F7F7F7;
    transition: color .25s ease-in; }
  body.dark-mode .projects-section .project-container .project-links .project-link a {
    color: #F7F7F7;
    transition: color .25s ease-in; }

.parent_project {
  transform: translateX(75vw);
  opacity: 0.1;
  transition: transform 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.parent_project.fade_in {
  transform: translateX(0);
  opacity: 1;
  transition: transform 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.contact-container {
  position: relative;
  overflow: hidden;
  background: #F7F7F7;
  font-family: "Montserrat", "Merriweather", "Times New Roman", Times, serif;
  z-index: 1;
  max-width: 100vw;
  transition: background .25s ease-in; }
  @media (max-width: 1400px) {
    .contact-container {
      width: 100%;
      height: 100%;
      padding: 4% 0;
      z-index: 1000; } }
  @media (max-width: 500px) {
    .contact-container {
      width: 100%;
      height: 100%;
      padding-bottom: 8%; } }
  .contact-container .form-container {
    margin: 2% 5% auto 27%;
    width: 52%;
    height: 85%;
    padding: 0 3rem 3rem 3rem;
    border-radius: 5px;
    z-index: 100; }
    @media (max-width: 1400px) {
      .contact-container .form-container {
        padding: 0;
        margin: 0 auto;
        width: 90%;
        height: 100%; } }
    @media (max-width: 500px) {
      .contact-container .form-container {
        margin: 10% auto 0 auto; } }
    .contact-container .form-container h2 {
      margin: 0;
      padding-top: 1rem;
      color: #1a1a1a;
      text-align: center;
      font-size: 3rem;
      height: 10%; }
      @media (max-width: 1400px) {
        .contact-container .form-container h2 {
          padding: 0;
          margin: 0;
          font-size: 2.4rem;
          padding: 5% 0; } }
      @media (max-width: 500px) {
        .contact-container .form-container h2 {
          padding: 8% 0;
          font-size: 1.4rem; } }
    .contact-container .form-container #contact_form {
      height: 42rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      position: relative; }
      @media (max-width: 500px) {
        .contact-container .form-container #contact_form {
          height: 100%; } }
      .contact-container .form-container #contact_form .input-container {
        width: 100%;
        display: flex;
        justify-content: space-between; }
        @media (max-width: 500px) {
          .contact-container .form-container #contact_form .input-container {
            flex-direction: column;
            height: 100%; } }
        .contact-container .form-container #contact_form .input-container .name,
        .contact-container .form-container #contact_form .input-container .phone,
        .contact-container .form-container #contact_form .input-container .email,
        .contact-container .form-container #contact_form .input-container .company {
          width: 48%; }
          @media (max-width: 1400px) {
            .contact-container .form-container #contact_form .input-container .name,
            .contact-container .form-container #contact_form .input-container .phone,
            .contact-container .form-container #contact_form .input-container .email,
            .contact-container .form-container #contact_form .input-container .company {
              width: 45%;
              margin: 3% 3%; } }
          @media (max-width: 500px) {
            .contact-container .form-container #contact_form .input-container .name,
            .contact-container .form-container #contact_form .input-container .phone,
            .contact-container .form-container #contact_form .input-container .email,
            .contact-container .form-container #contact_form .input-container .company {
              width: 90%;
              margin: 0 auto;
              padding: 2%; } }
        .contact-container .form-container #contact_form .input-container input {
          width: 100%;
          height: 65%;
          margin: 1rem 0;
          font-size: 1.2rem;
          color: #1f1f1f;
          letter-spacing: 1px;
          border: none;
          fill: solid;
          background: gainsboro;
          border-radius: 5px;
          font-family: "Montserrat", "Merriweather", "Times New Roman", Times, serif;
          padding-left: .6rem; }
          .contact-container .form-container #contact_form .input-container input::-webkit-input-placeholder {
            font-size: 1rem; }
          .contact-container .form-container #contact_form .input-container input:-ms-input-placeholder {
            font-size: 1rem; }
          .contact-container .form-container #contact_form .input-container input::-ms-input-placeholder {
            font-size: 1rem; }
          .contact-container .form-container #contact_form .input-container input::placeholder {
            font-size: 1rem; }
          @media (max-width: 500px) {
            .contact-container .form-container #contact_form .input-container input {
              font-size: 1rem;
              width: 100%;
              height: 100%;
              margin: 5% 0;
              border-radius: 2.5px;
              padding: 2.5% 0; } }
        .contact-container .form-container #contact_form .input-container label {
          font-size: 1.1rem;
          color: #1f1f1f;
          display: flex;
          font-weight: bold;
          justify-content: space-between; }
          @media (max-width: 1400px) {
            .contact-container .form-container #contact_form .input-container label {
              font-size: 1.2rem; } }
          @media (max-width: 500px) {
            .contact-container .form-container #contact_form .input-container label {
              font-size: 1rem; } }
          .contact-container .form-container #contact_form .input-container label p {
            text-align: right;
            width: 55%;
            font-size: .6rem;
            color: #ff5252; }
      .contact-container .form-container #contact_form .message {
        display: flex;
        flex-direction: column;
        font-size: 1.3rem;
        width: 99%; }
        @media (max-width: 1400px) {
          .contact-container .form-container #contact_form .message label {
            width: 94%;
            margin: 3% auto; } }
        @media (max-width: 500px) {
          .contact-container .form-container #contact_form .message label {
            width: 92%;
            margin: 0 auto 4% auto; } }
        .contact-container .form-container #contact_form .message textarea {
          margin-top: 1rem;
          width: 100%;
          font-size: 1.2rem;
          line-height: 1.5;
          padding: 0.6rem;
          color: #1a1a1a;
          letter-spacing: 1px;
          border: none;
          fill: solid;
          background: gainsboro;
          border-radius: 5px;
          font-family: "Montserrat", "Merriweather", "Times New Roman", Times, serif; }
          @media (max-width: 1400px) {
            .contact-container .form-container #contact_form .message textarea {
              font-size: 1.2rem;
              line-height: 1.25;
              padding: 0;
              letter-spacing: 0;
              border-radius: 2.5px;
              width: 96%;
              margin: 0 auto; } }
          @media (max-width: 500px) {
            .contact-container .form-container #contact_form .message textarea {
              font-size: 1rem;
              width: 92%; } }
    .contact-container .form-container .button_message {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-content: center;
      height: 10%; }
      @media (max-width: 1400px) {
        .contact-container .form-container .button_message {
          width: 94%;
          margin: 5% auto; } }
      @media (max-width: 500px) {
        .contact-container .form-container .button_message {
          height: 100%;
          flex-direction: column;
          align-content: flex-start; } }
      .contact-container .form-container .button_message button {
        border: none;
        outline: none;
        color: #F7F7F7;
        background: #0097a7;
        font-size: 1.4rem;
        font-family: "Montserrat", "Merriweather", "Times New Roman", Times, serif;
        cursor: pointer;
        border-radius: 5px;
        width: 22.5%;
        height: 100%;
        box-shadow: 2px 2px 5px rgba(39, 39, 39, 0.8); }
        @media (max-width: 1400px) {
          .contact-container .form-container .button_message button {
            padding: .5rem 0; } }
        @media (max-width: 500px) {
          .contact-container .form-container .button_message button {
            margin: 8% auto 8% auto;
            padding: 5% 0;
            width: 92%;
            font-size: 1.2rem; } }
      .contact-container .form-container .button_message .response-message {
        width: 70%;
        margin: auto 0;
        color: #0097a7;
        font-size: 1.6rem;
        font-weight: 800; }
        @media (max-width: 1400px) {
          .contact-container .form-container .button_message .response-message {
            font-size: 1.4rem;
            width: auto; } }
        @media (max-width: 500px) {
          .contact-container .form-container .button_message .response-message {
            margin: 0% auto 8% auto;
            width: 92%;
            font-size: 1.2rem;
            line-height: 1.5; } }
    .contact-container .form-container .social {
      display: flex;
      height: 100%;
      align-items: center; }
      @media (max-width: 500px) {
        .contact-container .form-container .social {
          width: 100%;
          justify-content: center;
          justify-content: space-between; } }
      .contact-container .form-container .social .link {
        margin-left: 2.4rem; }
        @media (max-width: 500px) {
          .contact-container .form-container .social .link {
            margin: 0;
            padding: 1rem 1.4rem 0 1.4rem; } }
        .contact-container .form-container .social .link img {
          width: 2.4rem;
          height: auto; }

.circle-one {
  height: 250px;
  width: 250px;
  background: #696969;
  background: linear-gradient(220deg, #0097a7 0%, #F7F7F7 100%);
  border-radius: 50%;
  position: absolute;
  top: 4rem;
  left: 14rem;
  position: absolute;
  z-index: -1; }

.circle-two {
  height: 175px;
  width: 175px;
  background: #696969;
  background: linear-gradient(220deg, #ff5252 0%, #F7F7F7 100%);
  border-radius: 50%;
  position: absolute;
  top: 22rem;
  left: 25rem;
  z-index: -1;
  transform: rotateZ(90deg); }

.circle-three {
  height: 222px;
  width: 222px;
  background: #696969;
  background: linear-gradient(220deg, #0097a7 0%, #F7F7F7 100%);
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  bottom: 1rem;
  left: 12rem; }

.circle-one,
.circle-two,
.circle-three {
  transition: background .25s ease-in; }
  @media (max-width: 1400px) {
    .circle-one,
    .circle-two,
    .circle-three {
      display: none; } }

body.dark-mode .contact-container {
  background: #1a1a1a;
  transition: background .25s ease-in; }
  body.dark-mode .contact-container h2 {
    color: #F7F7F7;
    transition: color .25s ease-in; }
  body.dark-mode .contact-container #contact_form .input-container label {
    color: #F7F7F7;
    transition: color .25s ease-in; }
  body.dark-mode .contact-container #contact_form .input-container input,
  body.dark-mode .contact-container #contact_form .input-container textarea {
    background: #F7F7F7;
    transition: background .25s ease-in; }
  body.dark-mode .contact-container .circle-one,
  body.dark-mode .contact-container .circle-three {
    background: linear-gradient(220deg, #0097a7 0%, #1a1a1a 100%);
    transition: background .25s ease-in; }
  body.dark-mode .contact-container .circle-two {
    background: linear-gradient(220deg, #ff5252 0%, #1a1a1a 100%);
    transition: background .25s ease-in; }

.about-container {
  height: 700px;
  max-width: 100vw;
  position: relative;
  font-family: "Montserrat", "Merriweather", "Times New Roman", Times, serif;
  background: #F7F7F7;
  z-index: 1;
  overflow: hidden;
  transition: background .25s ease-in; }
  @media (max-width: 1400px) {
    .about-container {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 100%;
      padding: 3rem 0; } }
  @media (max-width: 500px) {
    .about-container {
      padding: 10% 0; } }
  .about-container .about-text {
    position: absolute;
    top: 10%;
    left: 20%;
    color: #1a1a1a;
    width: 38%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
    transition: color .25s ease-in; }
    @media (max-width: 1400px) {
      .about-container .about-text {
        top: auto;
        left: auto;
        position: relative;
        padding: 0;
        width: 90%;
        margin: 0 auto;
        height: auto;
        margin-bottom: 5%; } }
    @media (max-width: 500px) {
      .about-container .about-text {
        position: relative;
        top: 0;
        left: 0;
        padding: 0 5%;
        width: 100%;
        height: 100%; } }
    .about-container .about-text h2 {
      font-size: 3rem;
      width: 100%; }
      @media (max-width: 1400px) {
        .about-container .about-text h2 {
          font-size: 2.8rem; } }
      @media (max-width: 500px) {
        .about-container .about-text h2 {
          font-size: 1.5rem;
          font-weight: 900;
          width: 90%; } }
      .about-container .about-text h2 span {
        color: #0097a7; }
    .about-container .about-text .copy {
      height: 95%;
      font-size: 1.3rem;
      color: #1a1a1a;
      line-height: 1.75;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin: auto 0;
      margin-top: 3%;
      padding: 2% 0;
      transition: color .25s ease-in; }
      @media (max-width: 1400px) {
        .about-container .about-text .copy {
          height: auto;
          line-height: 1.7;
          margin-top: 0; } }
      @media (max-width: 500px) {
        .about-container .about-text .copy {
          line-height: 1.5;
          width: 100%;
          font-size: 1rem; } }
      .about-container .about-text .copy p {
        height: 33%;
        padding-bottom: 40px; }
        @media (max-width: 1400px) {
          .about-container .about-text .copy p {
            font-size: 1.4rem;
            height: auto;
            padding: 2rem 0 0 0; } }
        @media (max-width: 800px) {
          .about-container .about-text .copy p {
            font-size: 1.3rem; } }
        @media (max-width: 500px) {
          .about-container .about-text .copy p {
            padding: 0;
            margin: 3% 0;
            line-height: 1.5;
            width: 90%;
            font-size: 1rem; } }
  .about-container .skills {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #1a1a1a;
    height: 80%;
    width: 28%;
    position: absolute;
    right: 10%;
    top: 12%;
    z-index: 1;
    border: 2px solid #0097a7;
    transition: color .25s ease-in; }
    @media (max-width: 1400px) {
      .about-container .skills {
        position: relative;
        left: auto;
        top: auto;
        right: auto;
        bottom: auto;
        width: 90%;
        margin: 0 auto;
        height: 100%; } }
    @media (max-width: 500px) {
      .about-container .skills {
        left: 5%;
        width: 90%;
        height: 100%;
        position: -webkit-sticky;
        position: sticky; } }
    .about-container .skills h3 {
      width: 100%;
      background: #0097a7;
      color: #F7F7F7;
      height: 10%;
      padding: 20px 0;
      font-size: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: 900;
      transition: color .25s ease-in;
      margin: 0; }
      @media (max-width: 1400px) {
        .about-container .skills h3 {
          padding: 1.5rem 0; } }
      @media (max-width: 500px) {
        .about-container .skills h3 {
          font-size: 1.2rem;
          text-align: center;
          line-height: 1.25; } }
    .about-container .skills ul {
      height: 100%;
      background: rgba(247, 247, 247, 0.5);
      display: flex;
      justify-content: space-evenly;
      transition: background .25s ease-in; }
      @media (max-width: 500px) {
        .about-container .skills ul {
          height: 100%; } }
      .about-container .skills ul .li-container {
        width: 50%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center; }
        @media (max-width: 500px) {
          .about-container .skills ul .li-container {
            padding: 5% 0; } }
        .about-container .skills ul .li-container li {
          font-size: 1.4rem;
          font-weight: 600;
          text-align: center;
          width: 100%;
          padding: 30px 0; }
          @media (max-width: 1400px) {
            .about-container .skills ul .li-container li {
              font-size: 1.5rem;
              padding: 2rem 0; } }
          @media (max-width: 800px) {
            .about-container .skills ul .li-container li {
              font-size: 1.3rem;
              padding: 1.3rem 0;
              padding: 10% 2%; } }
  .about-container .background-text {
    color: #efefef;
    position: absolute;
    font-weight: 800;
    font-size: 6rem;
    z-index: 0;
    transition: color .25s ease-in; }
  .about-container .javascript {
    top: 4%;
    right: 7%; }
  .about-container .css {
    top: 12%;
    left: 12%; }
  .about-container .react {
    top: 25%;
    right: 20%; }
  .about-container .node {
    top: 45%;
    right: 2%; }
  .about-container .html {
    bottom: 3%;
    right: 3%; }
  .about-container .python {
    bottom: 20%;
    right: 15%; }
  .about-container .express {
    bottom: 3%;
    left: 17%; }

body.dark-mode .about-container {
  background: #1a1a1a;
  transition: background .25s ease-in; }
  body.dark-mode .about-container .about-text {
    color: #F7F7F7;
    transition: color .25s ease-in; }
    body.dark-mode .about-container .about-text .copy {
      color: #F7F7F7;
      transition: color .25s ease-in; }
  body.dark-mode .about-container .background-text {
    color: #1f1f1f;
    transition: color .25s ease-in; }
  body.dark-mode .about-container .skills {
    color: #F7F7F7;
    transition: color .25s ease-in; }
    body.dark-mode .about-container .skills h3 {
      color: #1a1a1a;
      transition: color .25s ease-in; }
    body.dark-mode .about-container .skills ul {
      background: rgba(31, 31, 31, 0.5);
      transition: background .25s ease-in; }

.footer {
  color: #1a1a1a;
  background: #F7F7F7;
  text-align: center;
  font-family: "Montserrat", "Merriweather", "Times New Roman", Times, serif;
  padding: 1.2rem 0; }

body.dark-mode .footer {
  background: #1a1a1a;
  color: #F7F7F7; }

.desktop {
  position: relative;
  height: 100%;
  width: 100%; }
  .desktop .resume {
    z-index: 10000;
    position: fixed;
    right: 2rem;
    top: 2rem; }
    @media (max-width: 1400px) {
      .desktop .resume {
        top: 12vh;
        right: 4%; } }
    @media (max-width: 500px) {
      .desktop .resume {
        left: 1rem; } }
    .desktop .resume img {
      width: 2.5rem;
      height: 2.5rem;
      -webkit-filter: drop-shadow(2px 2px 2px #696969);
              filter: drop-shadow(2px 2px 2px #696969); }
      @media (max-width: 1400px) {
        .desktop .resume img {
          width: 3rem;
          height: 3rem;
          padding: 8px;
          border-radius: 50%;
          background: #f7f7f7; } }
      @media (max-width: 800px) {
        .desktop .resume img {
          width: 2.2rem;
          height: 2.2rem; } }
      @media (max-width: 500px) {
        .desktop .resume img {
          width: 1.4rem;
          height: 1.4rem; } }
  .desktop .dark {
    z-index: 10001;
    position: fixed;
    left: 12%;
    top: 2rem;
    height: 3rem;
    width: 3rem;
    cursor: pointer; }
    @media (max-width: 1400px) {
      .desktop .dark {
        top: 20vh;
        left: 90%; } }
    @media (max-width: 800px) {
      .desktop .dark {
        left: 89.5%; } }
    @media (max-width: 500px) {
      .desktop .dark {
        top: 12vh;
        left: 87%; } }
    .desktop .dark img {
      height: 100%; }
      @media (max-width: 1400px) {
        .desktop .dark img {
          -webkit-filter: drop-shadow(1px 1px 5px #696969);
                  filter: drop-shadow(1px 1px 5px #696969);
          padding: 8px;
          border-radius: 50%;
          background: #f7f7f7; } }
      @media (max-width: 800px) {
        .desktop .dark img {
          width: 2.2rem;
          height: 2.2rem; } }
      @media (max-width: 500px) {
        .desktop .dark img {
          width: 1.4rem;
          height: 1.4rem; } }
  @media (max-width: 1400px) {
    .desktop .dark.off img, .desktop .resume.off img {
      background: #1a1a1a;
      -webkit-filter: drop-shadow(1px 1px 5px #363636);
              filter: drop-shadow(1px 1px 5px #363636); } }

