.footer {
   
   color : $background-color;
   background: $font-color;
   text-align: center;
   font-family: "Montserrat", "Merriweather", "Times New Roman", Times, serif;
   padding: 1.2rem 0;

}

body.dark-mode .footer{
   background: $background-color;
   color: $font-color;
}