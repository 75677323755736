.contact-container {
    position: relative;
    overflow: hidden;
    background: $font-color;
    font-family: "Montserrat", "Merriweather", "Times New Roman", Times, serif;
    z-index: 1;
    max-width: 100vw;
    transition: background .25s ease-in;

    @include xl-tablet {
        width: 100%;
        height: 100%;
        padding: 4% 0;
        z-index: 1000;
    }

    @include mobile {
        width: 100%;
        height: 100%;
        padding-bottom: 8%;
    }

    .form-container {
        margin: 2% 5% auto 27%;
        width: 52%;
        height: 85%;
        padding: 0 3rem 3rem 3rem;
        border-radius: 5px;
        z-index: 100;

        @include xl-tablet {
            // background-color: rgba(lighten($background-color, 10%), 1);
            padding: 0;
            margin: 0 auto;
            width: 90%;
            height: 100%;
        }

        @include mobile {
            margin: 10% auto 0 auto;
        }

        h2 {
            margin: 0;
            padding-top: 1rem;
            color: $background-color;
            text-align: center;
            font-size: 3rem;
            height: 10%;

            @include xl-tablet {
                padding: 0;
                margin: 0;
                font-size: 2.4rem;
                padding: 5% 0;
            }

            @include mobile {
                padding: 8% 0;
                font-size: 1.4rem;
            }
        }

        #contact_form {
            height: 42rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            position: relative;

            @include mobile {
                height: 100%;
            }

            .input-container {
                width: 100%;
                display: flex;
                justify-content: space-between;

                @include mobile {
                    flex-direction: column;
                    height: 100%;
                }

                .name,
                .phone,
                .email,
                .company {
                    width: 48%;

                    @include xl-tablet {
                        width: 45%;
                        margin: 3% 3%;
                    }

                    @include mobile {
                        width: 90%;
                        margin: 0 auto;
                        padding: 2%;
                    }
                }

                input {
                    width: 100%;
                    height: 65%;
                    margin: 1rem 0;
                    font-size: 1.2rem;
                    color: $landing-font-background;
                    letter-spacing: 1px;
                    border: none;
                    fill: solid;
                    background: lighten($light-grey, 45%);
                    border-radius: 5px;
                    font-family: "Montserrat", "Merriweather", "Times New Roman", Times,
                        serif;
                    padding-left: .6rem;

                    &::placeholder {
                        font-size: 1rem;
                    }

                    @include mobile {
                        font-size: 1rem;
                        width: 100%;
                        height: 100%;
                        margin: 5% 0;
                        border-radius: 2.5px;
                        padding: 2.5% 0;
                    }
                }

                label {
                    font-size: 1.1rem;
                    color: $landing-font-background;
                    display: flex;
                    font-weight: bold;
                    justify-content: space-between;

                    @include xl-tablet {
                        font-size: 1.2rem;
                    }

                    @include mobile {
                        font-size: 1rem;
                    }

                    p {
                        text-align: right;
                        width: 55%;
                        font-size: .6rem;
                        color: $orange;
                    }
                }
            }

            .message {
                display: flex;
                flex-direction: column;
                font-size: 1.3rem;
                width: 99%;

                label {
                    @include xl-tablet {
                        width: 94%;
                        margin: 3% auto;
                    }

                    @include mobile {
                        width: 92%;
                        margin: 0 auto 4% auto;
                    }
                }

                textarea {
                    margin-top: 1rem;
                    width: 100%;
                    font-size: 1.2rem;
                    line-height: 1.5;
                    padding: 0.6rem;
                    color: $background-color;
                    letter-spacing: 1px;
                    border: none;
                    fill: solid;
                    background: lighten($light-grey, 45%);
                    border-radius: 5px;
                    font-family: "Montserrat", "Merriweather", "Times New Roman", Times,
                        serif;

                    @include xl-tablet {
                        font-size: 1.2rem;
                        line-height: 1.25;
                        padding: 0;
                        letter-spacing: 0;
                        border-radius: 2.5px;
                        width: 96%;
                        margin: 0 auto;
                    }

                    @include mobile {
                        font-size: 1rem;
                        width: 92%;
                    }
                }
            }
        }

        .button_message {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-content: center;
            height: 10%;

            @include xl-tablet {
                width: 94%;
                margin: 5% auto;
            }

            @include mobile {
                height: 100%;
                flex-direction: column;
                align-content: flex-start;
            }

            button {
                border: none;
                outline: none;
                color: $font-color;
                background: $green;
                font-size: 1.4rem;
                font-family: "Montserrat", "Merriweather", "Times New Roman", Times,
                    serif;
                cursor: pointer;
                border-radius: 5px;
                width: 22.5%;
                height: 100%;
                box-shadow: 2px 2px 5px rgba(lighten($background-color, 5%), .8);

                @include xl-tablet {
                    padding: .5rem 0;
                }

                @include mobile {
                    margin: 8% auto 8% auto;
                    padding: 5% 0;
                    width: 92%;
                    font-size: 1.2rem;
                }
            }

            .response-message {
                width: 70%;
                margin: auto 0;
                color: $green;
                font-size: 1.6rem;
                font-weight: 800;

                @include xl-tablet {
                    font-size: 1.4rem;
                    width: auto;
                }

                @include mobile {
                    margin: 0% auto 8% auto;
                    width: 92%;
                    font-size: 1.2rem;
                    line-height: 1.5;
                }
            }
        }

        .social {
            display: flex;
            height: 100%;
            align-items: center;
            @include mobile{
                width: 100%;
                justify-content: center;
                justify-content: space-between;
            }
            .link{
                margin-left: 2.4rem;
                @include mobile{
                    margin: 0;
                    padding: 1rem 1.4rem 0 1.4rem;
                }
                img{
                    width: 2.4rem;
                    height: auto;

                }
            }            

            
        }
    }
}

.circle-one {
    height: 250px;
    width: 250px;
    background: $light-grey;
    background: linear-gradient(220deg, $green 0%, $font-color 100%);
    border-radius: 50%;
    position: absolute;
    top: 4rem;
    left: 14rem;
    position: absolute;
    z-index: -1;
}

.circle-two {
    height: 175px;
    width: 175px;
    background: $light-grey;
    background: linear-gradient(220deg, $orange 0%, $font-color 100%);
    border-radius: 50%;
    position: absolute;
    top: 22rem;
    left: 25rem;
    z-index: -1;
    transform: rotateZ(90deg);
}

.circle-three {
    height: 222px;
    width: 222px;
    background: $light-grey;
    background: linear-gradient(220deg, $green 0%, $font-color 100%);
    border-radius: 50%;
    position: absolute;
    z-index: -1;
    bottom: 1rem;
    left: 12rem;
}

.circle-one,
.circle-two,
.circle-three {
    transition: background .25s ease-in;

    @include xl-tablet {
        display: none;
    }
}

body.dark-mode .contact-container {
    background: $background-color;
    transition: background .25s ease-in;

    h2 {
        color: $font-color;
        transition: color .25s ease-in;

    }

    #contact_form {

        .input-container {
            label {
                color: $font-color;
                transition: color .25s ease-in;

            }

            input,
            textarea {
                background: $font-color;
                transition: background .25s ease-in;

            }

        }

    }

    .circle-one,
    .circle-three {
        background: linear-gradient(220deg, $green 0%, $background-color 100%);
        transition: background .25s ease-in;
    }

    .circle-two {
        background: linear-gradient(220deg, $orange 0%, $background-color 100%);
        transition: background .25s ease-in;
    }

}