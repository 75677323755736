.about-container {
    height: 700px;
    max-width: 100vw;
    position: relative;
    font-family: "Montserrat", "Merriweather", "Times New Roman", Times, serif;
    background: $font-color;
    z-index: 1;
    overflow: hidden;
    transition: background .25s ease-in;

    @include xl-tablet {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
        padding: 3rem 0;
    }

    @include mobile {
        padding: 10% 0;
    }

    .about-text {
        position: absolute;
        top: 10%;
        left: 20%;
        color: $background-color;
        width: 38%;
        
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 1;
        transition: color .25s ease-in;


        @include xl-tablet {
            top: auto;
            left: auto;
            position: relative;
            padding: 0;
            width: 90%;
            margin: 0 auto;
            height: auto;
            margin-bottom: 5%;
        }



        @include mobile {
            position: relative;
            top: 0;
            left: 0;
            padding: 0 5%;
            width: 100%;
            height: 100%;
        }

        h2 {
            font-size: 3rem;
            width: 100%;

            @include xl-tablet {
                font-size: 2.8rem;
            }

            @include mobile {
                font-size: 1.5rem;
                font-weight: 900;
                width: 90%;
            }

            span {
                color: $green;
            }
        }

        .copy {
            height: 95%;
            font-size: 1.3rem;
            color: $background-color;
            line-height: 1.75;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            margin: auto 0;
            margin-top: 3%;
            padding: 2% 0;
            transition: color .25s ease-in;


            @include xl-tablet {
                height: auto;
                line-height: 1.7;
                margin-top: 0;
            }

            @include mobile {
                line-height: 1.5;
                width: 100%;
                font-size: 1rem;
            }

            p {
                
                height: 33%;
                padding-bottom: 40px;

                @include xl-tablet {
                    font-size: 1.4rem;
                    height: auto;
                    padding: 2rem 0 0 0;
                }

                @include tablet {
                    font-size: 1.3rem;
                }

                @include mobile {
                    padding: 0;      
                    margin: 3% 0;
                    line-height: 1.5;
                    width: 90%;
                    font-size: 1rem;
                }
            }
        }
    }

    .skills {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: $background-color;
        height: 80%;
        width: 28%;
        position: absolute;
        right: 10%;
        top: 12%;
        z-index: 1;
        border: 2px solid $green;
        transition: color .25s ease-in;

        @include xl-tablet {
            position: relative;
            left: auto;
            top: auto;
            right: auto;
            bottom: auto;
            width: 90%;
            margin: 0 auto;
            height: 100%;
        }

        @include mobile {
            left: 5%;
            width: 90%;
            height: 100%;
            position: sticky;
        }

        h3 {
            width: 100%;
            background: $green;
            color: $font-color;
            height: 10%;
            padding: 20px 0;
            font-size: 1.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-weight: 900;
            transition: color .25s ease-in;
            margin: 0;
            @include xl-tablet {
                padding: 1.5rem 0;
            }

            @include mobile {
                font-size: 1.2rem;
                text-align: center;
                line-height: 1.25;
            }
        }

        ul {
            height: 100%;
            background: rgba($font-color, 0.5);
            display: flex;
            justify-content: space-evenly;
            transition: background .25s ease-in;

            @include mobile {
                height: 100%;
            }

            .li-container {
                width: 50%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                align-items: center;

                @include mobile {
                    padding: 5% 0;
                }

                li {
                    font-size: 1.4rem;
                    font-weight: 600;
                    text-align: center;
                    width: 100%;
                    padding: 30px 0;

                    @include xl-tablet {
                        font-size: 1.5rem;
                        padding: 2rem 0;
                    }

                    @include tablet {
                        font-size: 1.3rem;
                        padding: 1.3rem 0;
                        padding:10% 2%;

                    }


                }
            }
        }
    }

    .background-text {
        color: darken($font-color, 3%);
        position: absolute;
        font-weight: 800;
        font-size: 6rem;
        z-index: 0;
        transition: color .25s ease-in;

    }

    .javascript {
        top: 4%;
        right: 7%;
    }

    .css {
        top: 12%;
        left: 12%;
    }

    .react {
        top: 25%;
        right: 20%;
    }

    .node {
        top: 45%;
        right: 2%;
    }

    .html {
        bottom: 3%;
        right: 3%;
    }

    .python {
        bottom: 20%;
        right: 15%;
    }

    .express {
        bottom: 3%;
        left: 17%;
    }
}

body.dark-mode .about-container{
    background: $background-color;
    transition: background .25s ease-in;

    .about-text{
        color:$font-color;
        transition: color .25s ease-in;
        .copy{
            color: $font-color;
            transition: color .25s ease-in;

        }
    }

    .background-text{
        color: $landing-font-background;
        transition: color .25s ease-in;

    }

    .skills{
        color: $font-color;
        transition: color .25s ease-in;

        h3{
            color:$background-color;
            transition: color .25s ease-in;

        }
        ul{
            background: rgba($landing-font-background, 0.5);
            transition: background .25s ease-in;

        }
    }
}